var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import $ from 'classnames';
import styles from './styles.module.scss';
import mainStyles from '../styles.module.scss';
import strings from '../l10n';
var AccessibleIcon = function (_a) {
    var iconName = _a.iconName, icons = _a.icons, srLabel = _a.srLabel;
    return (React.createElement(React.Fragment, null,
        iconName === "acorn" && React.createElement("i", { className: mainStyles.acorn, "aria-hidden": "true" }),
        iconName !== "acorn" && (icons ? React.createElement("i", { className: "cb-icon cb-" + iconName + " " + styles.arrowBg, "aria-hidden": "true" }) :
            React.createElement("i", { className: "cb-glyph cb-" + iconName + " " + styles.arrowBg, "aria-hidden": "true" })),
        React.createElement("span", { className: "sr-only", "aria-hidden": "true" }, srLabel)));
};
var CBLogo = function (_a) {
    var isCorporate = _a.isCorporate;
    return (React.createElement(React.Fragment, null,
        isCorporate ?
            React.createElement("i", { className: $(styles.logo, 'cb-glyph-logo cb-logo'), "aria-hidden": "true" })
            :
                React.createElement("i", { className: mainStyles.acorn, "aria-hidden": "true" }),
        React.createElement("span", { className: "sr-only", "aria-hidden": "true" }, strings.openGlobalNavigation)));
};
var MenuControl = /** @class */ (function (_super) {
    __extends(MenuControl, _super);
    function MenuControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.anchorRef = React.createRef();
        return _this;
    }
    MenuControl.prototype.onclick = function (e) {
        e.preventDefault();
        this.props.togglePanel('links');
    };
    MenuControl.prototype.focus = function () {
        this.anchorRef.current.focus();
    };
    MenuControl.prototype.render = function () {
        var _a = this.props, label = _a.label, controls = _a.controls, programType = _a.programType, activePanel = _a.activePanel, icons = _a.icons;
        var isCorporate = programType === 'corporate';
        var isOpen = activePanel === 'links';
        return (React.createElement("div", { className: $(styles.menu) },
            React.createElement("a", { ref: this.anchorRef, href: "#widget-menu-control", onClick: this.onclick.bind(this), "aria-expanded": isOpen ? 'true' : 'false', "aria-label": label, "aria-controls": isOpen ? controls : null, "data-cbtrack-linktype": "toggle" }, isOpen ? (React.createElement(AccessibleIcon, { iconName: "x-mark", srLabel: strings.closeGlobalNavigation, icons: icons })) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "visible-xs hidden-sm-up" }, isCorporate ? (React.createElement(CBLogo, { isCorporate: isCorporate })) : (React.createElement(AccessibleIcon, { iconName: "acorn", srLabel: strings.openGlobalNavigation, icons: icons }))),
                React.createElement("div", { className: "hidden-xs" },
                    React.createElement(AccessibleIcon, { iconName: "down", srLabel: strings.openGlobalNavigation, icons: icons })))))));
    };
    return MenuControl;
}(React.Component));
export default MenuControl;

import each from 'lodash/each';
import { extendObservable } from 'mobx';
import { globalStore } from './GlobalStore';
export default (function (multilangDictionary) {
    var dictionary = {};
    each(multilangDictionary, function (_a, phrase) {
        var _b;
        var englishTranslation = _a[0], spanishTranslation = _a[1];
        extendObservable(dictionary, (_b = {},
            Object.defineProperty(_b, phrase, {
                get: function () {
                    return globalStore.isEnglish ? englishTranslation : spanishTranslation;
                },
                enumerable: false,
                configurable: true
            }),
            _b));
    });
    return dictionary;
});

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './styles.module.scss';
import last from 'lodash/last';
import $ from 'classnames';
var Panel = /** @class */ (function (_super) {
    __extends(Panel, _super);
    function Panel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { height: 0, transitioning: false, currentState: 'closed' };
        _this.ref = React.createRef();
        _this.setHeight = function () {
            var el = _this.ref.current;
            var child = el.firstElementChild;
            var windowWidth = window.innerWidth;
            var windowHeight = window.innerHeight;
            ;
            var childHeight = 0;
            if (child) {
                childHeight = child.offsetHeight;
            }
            var height = childHeight;
            if (_this.props.adjustHeight) {
                if (windowWidth < 768) {
                    height += 100;
                }
            }
            if (child) {
                el.style.height = _this.props.isOpen ? (height + 'px') : '0px';
            }
        };
        _this.getHeight = function () {
            var el = _this.ref.current;
            var child = el.firstElementChild;
            var windowWidth = window.innerWidth;
            var windowHeight = window.innerHeight;
            ;
            var childHeight = 0;
            if (child) {
                childHeight = child.offsetHeight;
            }
            var height = childHeight;
            if (_this.props.adjustHeight) {
                if (windowWidth < 768) {
                    height += 100;
                }
            }
            return _this.props.isOpen ? height : 0;
        };
        _this.setHeightInstantly = function () {
            if (_this.ref.current) {
                var classes_1 = _this.ref.current.classList;
                classes_1.remove(styles.transition);
                _this.setHeight();
                setTimeout(function () { classes_1.add(styles.transition); }, 1);
            }
        };
        return _this;
    }
    Panel.prototype.componentWillReceiveProps = function (_a) {
        var isOpen = _a.isOpen;
        if (isOpen !== this.props.isOpen) {
            this.setState({ transitioning: true });
        }
    };
    Panel.prototype.render = function () {
        var _this = this;
        var _a = this.props, isOpen = _a.isOpen, children = _a.children, id = _a.id, className = _a.className, adjustHeight = _a.adjustHeight, role = _a.role, label = _a.label, scroll = _a.scroll, onTabOut = _a.onTabOut, linkGroup = _a.linkGroup;
        var transitioning = this.state.transitioning;
        return (React.createElement("div", { onKeyDown: function (e) {
                var pressedTab = e.key === 'Tab' && !e.shiftKey;
                var lastTabbable = last(_this.ref.current.querySelectorAll('input, a, button'));
                if (pressedTab && e.target === lastTabbable)
                    onTabOut(e);
            }, id: id, className: $(styles.panel, className), style: { overflow: transitioning ? 'hidden' : scroll ? 'auto' : 'visible' }, role: role, "aria-label": label, "aria-hidden": isOpen ? 'false' : 'true', "data-cbtrack-linkgroup": linkGroup },
            React.createElement("div", { ref: this.ref, className: $(styles.panelInner, styles.transition, className), onTransitionEnd: function () {
                    var currentState = _this.getHeight() <= 0 ? 'closed' : 'open';
                    if (_this.getHeight()) {
                        if (_this.props.onOpen && _this.state.currentState === 'closed') {
                            _this.props.onOpen();
                        }
                    }
                    _this.setState({ transitioning: false, currentState: currentState });
                } }, (isOpen || transitioning) && children)));
    };
    Panel.prototype.componentDidMount = function () { window.addEventListener('resize', this.setHeightInstantly, false); };
    Panel.prototype.componentWillUnmount = function () { window.addEventListener('resize', this.setHeightInstantly, false); };
    Panel.prototype.componentDidUpdate = function () { this.setHeight(); };
    return Panel;
}(React.Component));
export default Panel;

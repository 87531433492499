var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from "react";
import { observer } from "mobx-react";
import { chunk } from "lodash";
import styles from "./footer.module.scss";
import $ from "classnames";
import { apricot4 } from "@widgets/core";
/*
const s = localize({
  top: ['Top', 'Parte superior'],
  backToTop: ['Back to top of page', 'Volver al principio de esta página'],
  programs: ['Programs', 'Nuestros programas'],
  theCollegeBoard: ['College Board', 'College Board'],
});
*/
var s = {
    top: "Top",
    backToTop: "Back to top of page",
    programs: "Programs",
    theCollegeBoard: "College Board",
};
var Footer = /** @class */ (function (_super) {
    __extends(Footer, _super);
    function Footer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Footer.prototype.render = function () {
        var props = this.props;
        return (React.createElement("div", { id: props.tracker.id, "data-cbtrack-widget": "footer", className: $("cb-widget", styles.general) },
            props.showBackToTopLink && (React.createElement("div", { className: styles.controls },
                React.createElement("a", { href: "#" + props.globalHeaderId, className: styles.top, "aria-label": s.backToTop, onClick: props.topClicked, "data-cbtrack-linktype": "toggle" },
                    s.top,
                    " ",
                    React.createElement("span", { "aria-hidden": "true", className: "cb-icon-icn_arrow-up" })))),
            React.createElement("div", { className: styles.navigation }, props.showLinks && (React.createElement("div", { className: $("container", styles.linksContainer) },
                React.createElement("div", { className: "row" },
                    React.createElement(Category, { heading: s.programs, links: props.programLinks, columns: 2 }),
                    React.createElement(Category, { heading: s.theCollegeBoard, links: props.organizationLinks }),
                    React.createElement(Category, { links: props.extraLinks, push: true }))))),
            React.createElement(FooterTrademark, null)));
    };
    Footer.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    Footer.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    Footer = __decorate([
        observer
    ], Footer);
    return Footer;
}(React.Component));
export { Footer };
// Footer Category Component
var Category = function (_a) {
    var _b;
    var links = _a.links, heading = _a.heading, _c = _a.push, push = _c === void 0 ? false : _c, _d = _a.columns, columns = _d === void 0 ? 1 : _d;
    // Create an array of links split into groups the length of size.
    var linkColumns = chunk(links, Math.ceil(links.length / columns));
    // Wrap link columns in <ul> and render each Link component within it.
    var uls = linkColumns.map(function (column, i) { return (React.createElement("ul", { key: "category-" + heading + "-" + i, className: "col-xs-" + 12 / columns + " col-sm-12 col-md-12 col-lg-12" }, column.map(function (link) { return (React.createElement(Link, __assign({ key: "link-" + link.name }, link))); }))); });
    return (
    // Add grid classes based on column position.
    React.createElement("div", { className: $(styles.column, "col-xs-12 col-sm-4 col-md-4 col-lg-3", (_b = {},
            _b["col-lg-push-3 offset-lg-3"] = push,
            _b)) },
        React.createElement("div", { className: styles.heading }, heading && React.createElement("h2", null, heading)),
        apricot4 ? React.createElement("div", { className: styles.wrapper }, uls) : uls));
};
// Footer Social Link Component
var SocialLink = function (_a) {
    var name = _a.name, href = _a.href, target = _a.target, glyph = _a.glyph;
    return (React.createElement("a", { "aria-label": name, href: href, target: target, "data-cbtrack-linktype": "nav", className: $(apricot4 && "cb-btn cb-btn-circle " + styles.apricot4Social) },
        React.createElement("span", { className: apricot4 ? "cb-glyph cb-" + glyph : "cb-glyph-xs cb-glyph-circular cb-icon-icn_" + glyph }),
        React.createElement("span", { "aria-hidden": "true", className: "sr-only" }, name)));
};
// Footer Link Component
var Link = function (_a) {
    var url = _a.url, label = _a.label, name = _a.name, linkCode = _a.linkCode, children = _a.children;
    return (React.createElement("li", null,
        (linkCode !== 'morecb') && (React.createElement("a", { href: url, dangerouslySetInnerHTML: { __html: label || name }, "data-cbtrack-linktype": "nav" })),
        children.length > 0 && (React.createElement("ul", { className: $("lv-link-group", styles.children) }, children.map(function (child) { return (React.createElement("li", { key: "link-" + child.name, className: $(apricot4 && styles.apricot4) },
            React.createElement(SocialLink, { href: child.url, name: child.name, target: child.target, glyph: child.glyph }))); })))));
};
// Footer Trademark Component
var FooterTrademark = function () { return (React.createElement("div", { className: "container" },
    React.createElement("p", { className: styles.trademark },
        "\u00A9 ",
        new Date().getFullYear(),
        " College Board",
        React.createElement("span", { className: "visible-lg-inline display-xs-none-only" }, " | "),
        React.createElement("br", { className: "hidden-lg display-lg-none" }),
        "PSAT/NMSQT is a registered trademark of the College Board and National Merit Scholarship Corporation."))); };

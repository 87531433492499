var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import $ from 'classnames';
import styles from './styles.module.scss';
import { LoginFormView, IdentityLinksView, CallToActionView } from '../views/';
import localization from '../localization';
/**
 * PersonalizedIdentityCore Component provides a slide down login form which specially used in GlobalHeader top right corner login form with default config option
 */
var PersonalizedIdentityCore = /** @class */ (function (_super) {
    __extends(PersonalizedIdentityCore, _super);
    function PersonalizedIdentityCore() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PersonalizedIdentityCore.prototype.render = function () {
        var _a = this.props, allLinks = _a.allLinks, isLoggedIn = _a.isLoggedIn, userType = _a.userType, userName = _a.userName, logoutUrl = _a.logoutUrl, redirectUrl = _a.redirectUrl, signupUrl = _a.signupUrl, endpointParams = _a.endpointParams, appId = _a.appId, tracker = _a.tracker, authenticationUrl = _a.authenticationUrl, forgotUsernameUrl = _a.forgotUsernameUrl, forgotPasswordUrl = _a.forgotPasswordUrl, logoutRedirectUrl = _a.logoutRedirectUrl;
        return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "identity", className: $("cb-widget", styles.root) },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row", "data-cbtrack-linkgroup": !isLoggedIn ? 'login' : null },
                    React.createElement("div", { className: $("wgd-personalized-identity", styles.panel) }, isLoggedIn ? (React.createElement("div", { className: $("wgd-loggedin", styles.container) },
                        React.createElement("div", { className: $(styles.wrapper) },
                            React.createElement(IdentityLinksView, { allLinks: allLinks, username: userName, userType: userType, redirectUrl: redirectUrl, logoutUrl: logoutUrl, appId: appId, theme: styles, logoutRedirectUrl: logoutRedirectUrl })))) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: $(styles.container) },
                            React.createElement("div", { className: $(styles.wrapper) },
                                React.createElement(LoginFormView, { authenticationUrl: authenticationUrl, forgotUsernameUrl: forgotUsernameUrl, forgotPasswordUrl: forgotPasswordUrl, redirectUrl: redirectUrl, endpointParams: endpointParams, appId: appId, theme: styles }))),
                        React.createElement("div", { className: $(styles.loggedOut) },
                            React.createElement("div", { className: $(styles.container) },
                                React.createElement("div", { className: $(styles.wrapper) },
                                    React.createElement(CallToActionView, { href: signupUrl, title: localization.dontHaveAccount, label: localization.createAccount, theme: styles })))))))))));
    };
    PersonalizedIdentityCore.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    PersonalizedIdentityCore.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    return PersonalizedIdentityCore;
}(Component));
export default PersonalizedIdentityCore;

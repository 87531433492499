var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, when } from 'mobx';
var SLOW_REQUEST = 3000;
var Request = /** @class */ (function () {
    function Request(name, promise) {
        var _this = this;
        this.name = name;
        this.state = 'pending';
        this.errorMessage = null;
        this.duration = null;
        this.isSlow = false;
        var startTime = performance.now();
        setTimeout(function () {
            if (_this.state === 'pending')
                _this.isSlow = true;
        }, SLOW_REQUEST);
        when(function () { return _this.state !== 'pending'; }, function () {
            _this.duration = performance.now() - startTime;
        });
        promise.then(function () { return _this.state = 'success'; });
        promise.catch(function (e) {
            _this.state = 'error';
            _this.errorMessage = e.message;
        });
    }
    __decorate([
        observable
    ], Request.prototype, "state", void 0);
    __decorate([
        observable
    ], Request.prototype, "errorMessage", void 0);
    __decorate([
        observable
    ], Request.prototype, "duration", void 0);
    __decorate([
        observable
    ], Request.prototype, "isSlow", void 0);
    return Request;
}());
export default Request;

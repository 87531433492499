exports = module.exports = require("../../../widgets/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget .IpPinJvUWX_gHR4UzPT9b{display:flex;overflow:hidden;align-items:center;justify-content:center;height:100%;padding-right:4px;padding-left:4px;margin-left:4px;margin-right:0px;min-width:44px}.cb-widget .IpPinJvUWX_gHR4UzPT9b:active,.cb-widget .IpPinJvUWX_gHR4UzPT9b:link,.cb-widget .IpPinJvUWX_gHR4UzPT9b:visited,.cb-widget .IpPinJvUWX_gHR4UzPT9b:hover{text-decoration:none;color:#221f1f}.cb-widget .IpPinJvUWX_gHR4UzPT9b:active span,.cb-widget .IpPinJvUWX_gHR4UzPT9b:link span,.cb-widget .IpPinJvUWX_gHR4UzPT9b:visited span,.cb-widget .IpPinJvUWX_gHR4UzPT9b:hover span{color:inherit}.cb-widget .IpPinJvUWX_gHR4UzPT9b .OHYx4pfmhf0MrQbqKGyX1{margin-right:8px;font-size:14px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.cb-widget .FDSV9GC0V9ooOviZEC8KW{font-size:32px !important;background-color:#000;min-width:1em}.cb-widget .FDSV9GC0V9ooOviZEC8KW::before,.cb-widget .FDSV9GC0V9ooOviZEC8KW::after{color:#fff}", ""]);

// exports
exports.locals = {
	"root": "IpPinJvUWX_gHR4UzPT9b",
	"name": "OHYx4pfmhf0MrQbqKGyX1",
	"userIcon": "FDSV9GC0V9ooOviZEC8KW"
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from 'mobx';
import { fromPromise } from 'mobx-utils';
import cookie from 'js-cookie';
import axios from 'axios';
import { dpStore, URL } from '@widgets/core';
import isEmpty from 'lodash/isEmpty';
import { includes } from 'lodash';
var cookieName = 'lv-notificationWidget-storage-cookie';
var defaults = {
    endpoint: 'https://www.collegeboard.org/api/v1/views/cbalerts.json',
    useService: true,
    includeTypes: [],
    queryParams: {}
};
var NotificationStore = /** @class */ (function () {
    function NotificationStore() {
        var _this = this;
        this.closedNotificationIds = observable(cookie.getJSON(cookieName) || []);
        this.closeNotification = function (id) {
            _this.closedNotificationIds.push(id);
            cookie.set(cookieName, _this.closedNotificationIds);
        };
    }
    Object.defineProperty(NotificationStore.prototype, "config", {
        get: function () {
            return __assign(__assign(__assign({}, defaults), this.props), dpStore.dp.notification.config);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationStore.prototype, "request", {
        get: function () {
            var notificationsURL = URL.withQueryParams(this.config.endpoint, this.config.queryParams);
            var notificationsP = axios.get(notificationsURL);
            this.tracker.watchPromise(notificationsP, 'fetch notifications');
            return fromPromise(notificationsP.then(function (res) { return res.data; }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationStore.prototype, "allNotifications", {
        get: function () {
            return this.config.useService
                ? (this.request.state === 'fulfilled' ? this.request.value : [])
                : this.config.notifications;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NotificationStore.prototype, "notifications", {
        get: function () {
            var _this = this;
            return this.allNotifications.filter(function (n) {
                return !includes(_this.closedNotificationIds, n.id) &&
                    isEmpty(_this.config.includeTypes) || includes(_this.config.includeTypes, n.type);
            });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], NotificationStore.prototype, "props", void 0);
    __decorate([
        computed
    ], NotificationStore.prototype, "config", null);
    __decorate([
        computed
    ], NotificationStore.prototype, "request", null);
    __decorate([
        computed
    ], NotificationStore.prototype, "allNotifications", null);
    __decorate([
        computed
    ], NotificationStore.prototype, "notifications", null);
    return NotificationStore;
}());
export default NotificationStore;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import $ from 'classnames';
import { take, isEmpty } from 'lodash';
import styles from './styles.module.scss';
import { focus } from '../utils';
var autocompleteA11yAttrs = {
    'role': 'listbox',
    'aria-label': 'suggested search results for your search term'
};
var SuggestionsList = /** @class */ (function (_super) {
    __extends(SuggestionsList, _super);
    function SuggestionsList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = React.createRef();
        return _this;
    }
    SuggestionsList.prototype.focus = function () {
        focus(this.ref.current.firstElementChild);
        return this.ref.current.firstElementChild;
    };
    SuggestionsList.prototype.updateFocus = function (element) {
        this.currentElement && this.currentElement.setAttribute('aria-selected', 'false');
        element.setAttribute('aria-selected', 'true');
        this.currentElement = element;
        this.props.onFocus(element.getAttribute('id'));
        focus(element);
    };
    SuggestionsList.prototype.render = function () {
        var _this = this;
        var _a = this.props, id = _a.id, suggestions = _a.suggestions, limit = _a.limit, theme = _a.theme;
        var suggestionClicked = function (value) { return function () {
            _this.props.onSelection(value);
        }; };
        var suggestionKeyDown = function (value) { return function (e) {
            e.preventDefault();
            var key = e.key, shiftKey = e.shiftKey, currentTarget = e.currentTarget;
            if (key === 'ArrowDown' || (key === 'Tab' && !shiftKey)) {
                _this.updateFocus(currentTarget.nextElementSibling || currentTarget.parentElement.firstElementChild);
            }
            else if (key === 'ArrowUp' || (key === 'Tab' && shiftKey)) {
                _this.updateFocus(currentTarget.previousElementSibling || currentTarget.parentElement.lastElementChild);
            }
            else if (key === 'Escape') {
                _this.props.onEscape();
            }
            else if (key === 'Enter') {
                e.currentTarget.click();
            }
        }; };
        return isEmpty(suggestions) ? null : (React.createElement("ul", __assign({ id: id, "data-cbtrack-linkgroup": "search-sugg", className: $(styles.list, theme.list), role: "listbox" }, autocompleteA11yAttrs, { ref: this.ref }), take(suggestions, limit).map(function (s) {
            return (React.createElement("li", { id: 'suggestion-' + s.id, key: s.id, className: $(styles.item, theme.item), "aria-selected": "false", role: "option", tabIndex: -1, onClick: suggestionClicked(s), onKeyDown: suggestionKeyDown(s), "data-cbtrack-linktype": "nav" }, s.label));
        })));
    };
    SuggestionsList = __decorate([
        observer
    ], SuggestionsList);
    return SuggestionsList;
}(React.Component));
export default SuggestionsList;

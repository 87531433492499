var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './styles.module.scss';
import $ from 'classnames';
var SearchWidgetContainer = /** @class */ (function (_super) {
    __extends(SearchWidgetContainer, _super);
    function SearchWidgetContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = React.createRef();
        return _this;
    }
    SearchWidgetContainer.prototype.render = function () {
        var _a = this.props, className = _a.className, id = _a.id, groupName = _a.groupName, children = _a.children;
        return (React.createElement("div", { id: id, ref: this.ref, "data-cbtrack-widget": groupName, className: $('cb-widget', styles.root, className) }, children));
    };
    SearchWidgetContainer.prototype.onClickOutside = function (e) {
        var targetNode = e.target;
        if (this.ref.current && targetNode) {
            if (!this.ref.current.contains(targetNode)) {
                this.props.onBlur();
            }
        }
    };
    SearchWidgetContainer.prototype.componentDidMount = function () {
        document.removeEventListener('mousedown', this.onClickOutside.bind(this));
        document.addEventListener('mousedown', this.onClickOutside.bind(this));
    };
    SearchWidgetContainer.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.onClickOutside.bind(this));
    };
    return SearchWidgetContainer;
}(React.Component));
export default SearchWidgetContainer;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import './index.scss';
import './apricot.less';
import 'core-js/es/symbol';
import { BackwardCompatible, Official } from './objects';
import { trackWidget } from '@widgets/core';
import set from 'lodash/set';
set(window, 'cb.widgets', __assign(__assign({}, BackwardCompatible), Official));
/* Utility functionality for anyone to use
*/
set(window, 'cb.widgets.utility', {
    trackWidget: trackWidget
});

/**
 * TODO: Jonathan (10-9-2020)
 * I have no idea why localize is not setting the dictionary. The mobx values are not getting set and the object is empty.
 * I ended up removing the translations and returning just plain english to make sure that text is at least printed out.
 */
import { localize } from '@widgets/core';
export var __localization = localize({
    signIn: [
        'Sign in to your account to get started',
        'Inicia sesión en tu cuenta para comenzar',
    ],
    dontHaveAccount: ["Don't have an account?", 'No tienes una cuenta?'],
    username: ['Username', 'Nombre de usuario'],
    password: ['Password', 'Contraseña'],
    createAccount: ['Create Account', 'Crear una Cuenta'],
    forgot: ['Forgot', 'Olvidaste'],
    rememberMe: ['Remember Me', 'Recuérdame'],
    submit: ['Submit', 'Enviar'],
    login: ['Log In', 'Iniciar sesión'],
    hello: ['Hello', 'Hola'],
    hi: ['Hi', 'Hola'],
    signOut: ['Sign Out', 'Desconectar'],
    signUp: ['Sign Up', 'Regístrate']
});
export var localization = {
    signIn: 'Sign in to your account to get started',
    dontHaveAccount: "Don't have an account?",
    username: 'Username',
    password: 'Password',
    createAccount: 'Create Account',
    forgot: 'Forgot',
    rememberMe: 'Remember Me',
    submit: 'Submit',
    login: 'Log In',
    hello: 'Hello',
    hi: 'Hi',
    signOut: 'Sign Out',
    signUp: 'Sign Up'
};
export default localization;

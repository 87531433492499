import React from 'react';
import { dpStore, widget, levityStore as levity } from '@widgets/core';
import PersonalizedIdentityCore from './PersonalizedIdentity';
import StandaloneIdentityCore from './StandaloneIdentity';
import HeroIdentityCore from './HeroIdentity';
import IdentityWidgetStore from './store';
/**
 *  PersonalizedIdentityWidget injected in widget HOC which decorates components with stores, tracker and Mobx observer
 */
export var PersonalizedIdentityWidget = widget('identity')(function () { return new IdentityWidgetStore; }, function (store, tracker) {
    return (React.createElement(PersonalizedIdentityCore, { tracker: tracker, appId: dpStore.appId, allLinks: store.allLinks, isLoggedIn: levity.isLoggedIn, userType: levity.userType, userName: levity.isLoggedIn && levity.firstName, forgotUsernameUrl: store.endpoint('forgotUsername'), authenticationUrl: store.endpoint('authentication'), forgotPasswordUrl: store.endpoint('forgotPassword'), logoutUrl: store.endpoint('logout'), signupUrl: store.endpoint('signUp'), redirectUrl: store.redirectUrl, rememberMe: store.rememberMe, endpointParams: store.config.endpointParams, logoutRedirectUrl: store.props.logoutRedirectUrl }));
});
/**
 *  IdentityWidget injected in widget HOC which decorates components with stores, tracker and Mobx observer
 */
export var IdentityWidget = widget('identity')(function () { return new IdentityWidgetStore; }, function (store, tracker) {
    return (React.createElement(StandaloneIdentityCore, { tracker: tracker, appId: dpStore.appId, allLinks: store.allLinks, isLoggedIn: levity.isLoggedIn, userType: levity.userType, userName: levity.isLoggedIn && levity.firstName, rememberMe: store.rememberMe, forgotUsernameUrl: store.endpoint('forgotUsername'), authenticationUrl: store.endpoint('authentication'), forgotPasswordUrl: store.endpoint('forgotPassword'), logoutUrl: store.endpoint('logout'), signupUrl: store.endpoint('signUp'), redirectUrl: store.redirectUrl, endpointParams: store.config.endpointParams, logoutRedirectUrl: store.props.logoutRedirectUrl }));
});
export default IdentityWidget;
/**
 *  HeroIdentityWidget injected in widget HOC which decorates components with stores, tracker and Mobx observer
 */
export var HeroIdentityWidget = widget('identity')(function () { return new IdentityWidgetStore(); }, function (store, tracker) {
    return (React.createElement(HeroIdentityCore, { tracker: tracker, appId: dpStore.appId, allLinks: store.allLinks, isLoggedIn: levity.isLoggedIn, userType: levity.userType, userName: levity.isLoggedIn && levity.firstName, rememberMe: store.rememberMe, forgotUsernameUrl: store.endpoint('forgotUsername'), authenticationUrl: store.endpoint('authentication'), forgotPasswordUrl: store.endpoint('forgotPassword'), logoutUrl: store.endpoint('logout'), signupUrl: store.endpoint('signUp'), redirectUrl: store.redirectUrl, endpointParams: store.config.endpointParams, preambleText: store.props.preambleText, headerText: store.props.headerText, bodyText: store.props.bodyText, program: store.props.program, bgColor: store.props.bgColor, imageUrl: store.props.imageUrl, logoutRedirectUrl: store.props.logoutRedirectUrl }));
});
/**
//const bgImage = 'https://uihubstg.wcm.collegeboard.org/ui/3.4.0/images/login-hero-image-desktop.jpg'
const bgImage = 'https://www.collegeboard.org/sites/default/files/Standard-Hero_BH_2560x384-Default.jpg'
type ProgramName = 'corporate' | 'k-12' | 'higher-education' | 'access'

interface ILoginHeroProps extends IdentityWidgetConfig {
  /** The CollegeBoard program (determines background color)
   * @default corporate
  program?: ProgramName
  imageUrl?: string
  headerText: string
  bodyText: string
}

const Hero = ({children, program, imageUrl, headerText, bodyText}) => {
  // Image Grid
  const imageGridClass3 = "col-xs-12 col-sm-6 col-md-offset-1 col-md-5"
  const imageGridClass4 = "col-xs-12 col-sm-6 offset-md-1 col-md-5 offset-lg-1 col-lg-5 offset-xl-1 col-xl-5 offset-2x-1 col-2xl-4 offset-3xl-1 col-3xl-5"

  // Form Grid
  const formGridClass3 = "col-xs-12 col-sm-6 col-md-5 col-lg-4 col-lg-offset-1"
  const formGridClass4 = "col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xl-5"

  return (
    <div className={`cb-login-hero cb-${program}-program`}>
      <div className="cb-login-img-container">
        <div className="container">
          <div className="row">
            <div style={{'height': '576px'}} className={imageGridClass4}>
              <img style={{'height': '100%'}} src={imageUrl} />
            </div>
          </div>
        </div>
      </div>
      <div className="cb-block-content container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-offset-1 col-md-7 col-lg-8 col-xl-6 col-2xl-6 offset-3xl-1 col-3xl-5 cb-hero-content">
            <div className="content">
              <h2 dangerouslySetInnerHTML={{__html: headerText}}/>
              <p dangerouslySetInnerHTML={{__html: bodyText}}/>
            </div>
          </div>
          <div className={formGridClass4}>
            <div className="content">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const HeroLoginWidget = widget<ILoginHeroProps>('identity')(() => new IdentityWidgetStore<ILoginHeroProps>(), (store, tracker) => {
  return (
    <div id={tracker.id} data-cbtrack-widget="identity" className="cb-widget">
      <Hero
        program={store.props.program}
        imageUrl={store.props.imageUrl}
        headerText={store.props.headerText}
        bodyText={store.props.bodyText}>
      <HeroIdentityWidget
        hero
        tracker={tracker}
        appId={dpStore.appId}
        allLinks={store.allLinks}
        isLoggedIn={levity.isLoggedIn}
        userType={levity.userType}
        userName={levity.isLoggedIn && levity.authSession.basicProfile.firstName}
        rememberMe={store.rememberMe}
        forgotUsernameUrl={store.endpoint('forgotUsername')}
        authenticationUrl={store.endpoint('authentication')}
        forgotPasswordUrl={store.endpoint('forgotPassword')}
        logoutUrl={store.endpoint('logout')}
        signupUrl={store.endpoint('signUp')}
        redirectUrl={store.redirectUrl}
        endpointParams={store.config.endpointParams}
      />
      </Hero>
    </div>
  )
})
*/ 

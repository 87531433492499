import '@atlas/levity';
import { get, set, isEmpty } from 'lodash';
if (isEmpty(get(window, 'cb.core.utils.DeploymentProfile'))) {
    set(window, 'cb.core.utils.DeploymentProfile', {
        iam: {
            aws: {}
        }
    });
}
export default window.cb.core.iam;

export default {
    sat: 'cb-programs-sat',
    pn: 'cb-programs-psat-nmsqt',
    pn10: 'cb-programs-psat-10',
    p89: 'cb-programs-psat-8-9',
    sb: 'cb-programs-springboard',
    ap: 'cb-programs-ap',
    accs: 'cb-programs-big-future-embed',
    accu: 'cb-programs-accuplacer',
    clep: 'cb-programs-clep',
    css: 'cb-programs-css-profile-embed',
    cbsch: 'cb-search',
    pfaid: 'cb-programs-powerfaids-embed'
};

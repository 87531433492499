var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { dpStore } from './deployment-profile/store';
import { observer } from 'mobx-react';
import trackWidget from './analytics/trackWidget';
export var widget = function (name) { return function (createStore, renderWidget) {
    var _a;
    return observer((_a = /** @class */ (function (_super) {
            __extends(WrappedComponent, _super);
            function WrappedComponent(props) {
                var _this = _super.call(this, props) || this;
                _this.tracker = trackWidget(name, 'atlas');
                _this.store = createStore();
                dpStore.appId = props.appId;
                _this.store.props = props;
                _this.store.tracker = _this.tracker;
                return _this;
            }
            WrappedComponent.prototype.componentDidCatch = function (e, info) {
                this.tracker.componentError(e.message, info.componentStack);
            };
            WrappedComponent.prototype.componentWillReceiveProps = function (newProps) {
                this.store.props = newProps;
            };
            WrappedComponent.prototype.render = function () {
                return !dpStore.loaded || (dpStore.dp[name] && dpStore.dp[name].disabled) ? null :
                    renderWidget(this.store, this.tracker);
            };
            return WrappedComponent;
        }(Component)),
        _a.render = function (element, props) {
            ReactDOM.render(React.createElement(_a, props), element instanceof Element ? element : document.querySelector(element));
        },
        _a));
}; };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import $ from 'classnames';
import styles from './styles.module.scss';
import debounce from 'lodash/debounce';
import { apricot4 } from '@widgets/core';
/** Icon Option */
var Icon = function (props) {
    return (React.createElement("i", { className: {
            'lv-success': 'cb-glyph cb-green1-color cb-glyph-circular cb-check',
            'lv-emergency': 'cb-glyph cb-red1-color cb-glyph-circular cb-exclamation'
        }[props.type] || 'cb-glyph cb-glyph-circular cb-megaphone', "aria-hidden": "true" }));
};
/** Alert Message Markup */
var Message = /** @class */ (function (_super) {
    __extends(Message, _super);
    function Message() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { height: 0, isClosing: false };
        _this.viewportRef = React.createRef();
        return _this;
    }
    Message.prototype.render = function () {
        var _a;
        var _b = this.props, id = _b.id, message = _b.message, type = _b.type;
        var _c = this.state, height = _c.height, isClosing = _c.isClosing;
        var style = { height: height + 'px' };
        if (isClosing) {
            style.height = '0px';
        }
        return (React.createElement("div", { id: 'notification-' + id, className: styles.notification, style: style },
            React.createElement("div", { ref: this.viewportRef, className: styles.viewport },
                React.createElement("div", { className: $(styles.icon, (_a = {}, _a["" + styles.apricot3] = !apricot4, _a)) },
                    React.createElement(Icon, { type: type })),
                React.createElement("div", { className: styles.message },
                    React.createElement("p", { dangerouslySetInnerHTML: { __html: message } })),
                React.createElement("div", { className: styles.control },
                    React.createElement("button", { onClick: this.onClose.bind(this) },
                        React.createElement("i", { className: "cb-glyph cb-x-mark" },
                            React.createElement("span", { className: "sr-only" }, "Close Notification")))))));
    };
    Message.prototype.addTracking = function () {
        this.viewportRef.current.querySelectorAll('a').forEach(function (a) {
            a.setAttribute('data-cbtrack-linktype', 'promo');
        });
    };
    Message.prototype.onClose = function () {
        var isClosing = this.state.isClosing;
        var onClose = this.props.onClose;
        if (!isClosing) {
            this.setState({ isClosing: true });
            setTimeout(function () { onClose(); }, 500);
        }
    };
    /**
     * Set the viewport height of the message.
     *
     * @param currentHeight The current height of the message
     * @param clientHeight The new height of the message
     * @param isInstant Instantly set the height
     * @returns boolean Whether we changed the height or not
     */
    Message.prototype.setViewportHeight = function (currentHeight, clientHeight, isInstant) {
        if (isInstant === void 0) { isInstant = false; }
        if (currentHeight !== clientHeight) {
            this.setState({
                height: clientHeight,
                isInstant: isInstant
            });
            return true;
        }
        return false;
    };
    Message.prototype.componentDidMount = function () {
        var _this = this;
        var height = this.state.height;
        var viewportRef = this.viewportRef;
        if (viewportRef.current) {
            this.setViewportHeight(height, viewportRef.current.getBoundingClientRect().height);
        }
        window.addEventListener('resize', function () {
            _this.setViewportHeight(height, viewportRef.current.getBoundingClientRect().height);
        });
        this.addTracking();
    };
    Message.prototype.componentDidUpdate = function () {
        this.addTracking();
        var height = this.state.height;
        var viewportRef = this.viewportRef;
        if (this.setViewportHeight(height, viewportRef.current.getBoundingClientRect().height, true)) {
            this.props.onChange();
        }
    };
    return Message;
}(Component));
var gridClass3 = "col-md-offset-1 col-md-10 col-lg-offset-2 col-lg-8";
var gridClass4 = "offset-md-1 col-md-10 offset-lg-2 col-lg-8 offset-xl-2 col-xl-8 offset-2xl-3 col-2xl-6 offset-3xl-3 col-3xl-6";
/** Widget Markup */
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.widgetRef = React.createRef();
        _this.containerRef = React.createRef();
        _this.state = { height: 0, isInstant: false };
        return _this;
    }
    default_1.prototype.render = function () {
        var _this = this;
        var _a = this.state, height = _a.height, isInstant = _a.isInstant;
        var _b = this.props, notifications = _b.notifications, tracker = _b.tracker;
        var style = { height: height + 'px' };
        if (isInstant) {
            style['transition'] = 'none';
        }
        var gridClass = apricot4 ? gridClass4 : gridClass3;
        return (React.createElement("div", { ref: this.widgetRef, style: style, id: tracker.id, className: $('cb-widget', styles.root), "data-cbtrack-widget": "notification" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: gridClass },
                        React.createElement("div", { ref: this.containerRef, className: $(styles.notifications) }, notifications.map(function (notification) {
                            return React.createElement(Message, __assign({ key: notification.id }, notification, { onClose: function () { return _this.onCloseNotification(notification.id); }, onChange: function () { return _this.updateViewportHeight(); } }));
                        })))))));
    };
    default_1.prototype.onCloseNotification = function (id) {
        this.props.closeNotification(id);
        this.updateViewportHeight();
    };
    /**
     * Save the current viewport's height when all of the notifications have been
     * rendered. There is a container that has a height of 0 pixels which hides the
     * calculation. Once all of the notifications have been rendered, it will
     * trigger the componentDidMount function which will then calculate how high
     * that inner viewport is. Once that viewport has been calculated, we set the
     * height of the container which a css transition that will animate the
     * container opening.
     *
     * NOTE: This is the only way to accomplish this transition. We have to calculate
     * in JavaScript and then inline set the height, otherwise, we will inadvertantly
     * limit the content by presetting a height. This ensures content growth when
     * necessary.
     *
     * @param currentHeight The current height of the container, starts at 0
     * @param clientHeight The height of the viewport
     */
    default_1.prototype.setViewportHeight = function (currentHeight, clientHeight, isInstant) {
        if (isInstant === void 0) { isInstant = false; }
        if (currentHeight !== clientHeight) {
            this.setState({
                height: clientHeight,
                isInstant: isInstant
            });
        }
    };
    default_1.prototype.updateViewportHeight = function () {
        var containerRef = this.containerRef;
        if (containerRef.current) {
            var n = this.props.notifications.length ? 1 : 0;
            this.setViewportHeight(this.state.height, containerRef.current.getBoundingClientRect().height * n);
        }
    };
    default_1.prototype.componentDidMount = function () {
        var _this = this;
        this.props.tracker.mounted();
        var containerRef = this.containerRef;
        // Do this at least once
        setTimeout(function () { _this.updateViewportHeight(); }, 1000);
        // Do this on resize
        window.addEventListener('resize', debounce(function () {
            if (containerRef.current) {
                _this.updateViewportHeight();
            }
        }, 350));
    };
    default_1.prototype.componentDidUpdate = function () {
        var _this = this;
        var containerRef = this.containerRef;
        // Do this at least once when updated
        setTimeout(function () { _this.updateViewportHeight(); }, 300);
        if (this.props.onWidgetMounted) {
            this.props.onWidgetMounted(this.props.notifications);
        }
    };
    default_1.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    return default_1;
}(Component));
export default default_1;

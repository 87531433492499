import { HeaderWidget } from '@widgets/header';
import { FooterWidget } from '@widgets/footer';
import { NotificationWidget } from '@widgets/notification';
import { CollegeSearchWidget } from '@widgets/search';
import StandaloneIdentityWidget, { PersonalizedIdentityWidget, HeroIdentityWidget, } from '@widgets/identity';
import { SiteSearchWidget } from '@widgets/sitesearch';
/*
import {TCollegeListWidget} from '@widgets/college-list';
import loadable from '@loadable/component';
import {withStaticRender} from '@widgets/core';

const DynamicCollegeListWidget = withStaticRender(
  loadable(() =>
    import('@widgets/college-list').then(d => d.CollegeListWidget),
  ) as TCollegeListWidget,
);
*/
export var BackwardCompatible = {
    HeaderWidget: HeaderWidget,
    FooterWidget: FooterWidget,
    NotificationWidget: NotificationWidget,
    CollegeSearchWidget: CollegeSearchWidget,
    HeroIdentityWidget: HeroIdentityWidget,
    SiteSearchWidget: SiteSearchWidget,
};
export var Official = {
    GlobalHeader: HeaderWidget,
    GlobalFooter: FooterWidget,
    Notification: NotificationWidget,
    SiteSearch: SiteSearchWidget,
    Identity: StandaloneIdentityWidget,
    HeroIdentity: HeroIdentityWidget,
    PersonalizedIdentity: PersonalizedIdentityWidget,
    CollegeSearch: CollegeSearchWidget,
};

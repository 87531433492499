exports = module.exports = require("../../../widgets/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._3sF0xcE68id3ka0BUvU-iF{overflow:hidden;position:absolute;z-index:100;border-bottom:1px solid #aeadad;background:#fff;padding:0;margin:0;width:100%}@media only screen and (max-width: 768px){.cb-widget ._3sF0xcE68id3ka0BUvU-iF{border-top:1px solid #aeadad}}.cb-widget ._3Bgfm6YkIlYWDnLNuChnKt{list-style:none;cursor:pointer;background:none;display:block;line-height:18px;font-size:14px;padding:9px 15px;color:#000}.cb-widget ._3Bgfm6YkIlYWDnLNuChnKt:hover,.cb-widget ._3Bgfm6YkIlYWDnLNuChnKt:focus{background:#e6e6e6}", ""]);

// exports
exports.locals = {
	"list": "_3sF0xcE68id3ka0BUvU-iF",
	"item": "_3Bgfm6YkIlYWDnLNuChnKt"
};
import React from 'react';
import styles from '../styles.module.scss';
import $ from 'classnames';
import queryString from 'querystring';
var sanitizeSearchParams = function (searchParams) {
    var params = {};
    ['tp', 'page', 'siteType', 'searchType', 'word', 'x1', 'x2', 'x3', 'q', 'q1', 'q2', 'q3', 'bf_cat'].map(function (param) {
        if (searchParams[param]) {
            params[param] = searchParams[param];
        }
    });
    return params;
};
var executeSearch = function (e, url, callback, isReset) {
    if (isReset === void 0) { isReset = false; }
    e.preventDefault();
    var urlParts = url.split('?');
    var pageUrl = urlParts[0];
    var urlParams = urlParts[1];
    var toParse = urlParts.length > 1 ? urlParams : pageUrl;
    var searchParams = queryString.parse(toParse);
    var params = sanitizeSearchParams(searchParams);
    if (isReset) {
        delete params['q1'];
        delete params['q2'];
        delete params['q3'];
        delete params['x1'];
        delete params['x2'];
        delete params['x3'];
    }
    callback(params);
};
export var FilterView = function (p) {
    var _a;
    var columns = p.columns, additionalParams = p.additionalParams, siteType = p.siteType, isFiltersOn = p.isFiltersOn, resetURL = p.resetURL, onFilterClick = p.onFilterClick, onUndoClick = p.onUndoClick, onResetClick = p.onResetClick, onBackClick = p.onBackClick;
    return (React.createElement("div", { className: $((_a = {},
            _a[styles.filters] = true,
            _a["" + styles[siteType]] = siteType ? true : false,
            _a)) },
        React.createElement("div", { className: $(styles.content) },
            React.createElement("div", { className: $(styles.controls, "visible-xs visible-sm") },
                React.createElement("a", { onClick: onBackClick }, "< Back to Search"),
                isFiltersOn && React.createElement("a", { className: $(styles.clear), onClick: function (e) { executeSearch(e, resetURL, onResetClick, true); }, href: resetURL },
                    "Clear All",
                    React.createElement("span", { className: "sr-only" }, "Filters"))),
            React.createElement("div", { className: $(styles.header) },
                React.createElement("h2", null, "Filters"),
                isFiltersOn && React.createElement("a", { className: $(styles.clear, "visible-md visible-lg"), onClick: function (e) { executeSearch(e, resetURL, onResetClick, true); }, href: resetURL },
                    "Clear All",
                    React.createElement("span", { className: "sr-only" }, "Filters"))),
            columns.map(function (column, idx) { return (React.createElement(FilterFacetView, { onFilterClick: onFilterClick, onUndoClick: onUndoClick, additionalParams: additionalParams, key: idx, title: column.title, items: column.facets })); }))));
};
export var FilterFacetView = function (p) {
    var onFilterClick = p.onFilterClick, onUndoClick = p.onUndoClick, title = p.title, items = p.items;
    return (React.createElement("div", { className: $(styles.facet) },
        React.createElement("h3", null,
            "By ",
            title),
        React.createElement("ul", null, items.map(function (item, idx) {
            return React.createElement(FacetView, { key: idx, isSelected: item.isSelected, query: item.query, title: item.title, total: item.total, undoquery: item.undoquery, onAddClick: onFilterClick, onRemoveClick: onUndoClick });
        }))));
};
export var FacetView = function (p) {
    var _a;
    var isSelected = p.isSelected, query = p.query, title = p.title, total = p.total, undoquery = p.undoquery, onAddClick = p.onAddClick, onRemoveClick = p.onRemoveClick;
    return (React.createElement("li", { className: $((_a = {},
            _a["" + styles.active] = isSelected,
            _a)) },
        React.createElement("a", { onClick: function (e) { executeSearch(e, query, onAddClick); }, href: query, "data-cbtrack-searchfacet": true },
            title,
            " ",
            !isSelected && React.createElement(React.Fragment, null,
                "(",
                total,
                ")")),
        isSelected && React.createElement("a", { onClick: function (e) { executeSearch(e, undoquery, onRemoveClick); }, href: undoquery },
            React.createElement("span", { className: "sr-only" },
                "Remove ",
                title,
                " filter"),
            React.createElement("i", { className: "glyphicon-cb cb-icon-icn_close" }))));
};
export var StatusView = function (p) {
    var pagelower = p.pagelower, pageupper = p.pageupper, total = p.total;
    return (React.createElement("div", { className: $(styles.status) },
        React.createElement("strong", null,
            "Displaying results ",
            pagelower,
            " - ",
            pageupper,
            " of ",
            total)));
};
export var BannersView = function (p) {
    var banners = p.banners;
    return (React.createElement(React.Fragment, null, banners.map(function (banner, idx) { return (banner.content !== '' && React.createElement(BannerView, { key: idx, id: banner.id, content: banner.content })); })));
};
export var BannerView = function (p) {
    var id = p.id, content = p.content;
    return (React.createElement("div", { "data-cbtrack-searchbanner": id, className: $(styles.banner) },
        React.createElement("div", { dangerouslySetInnerHTML: { __html: content } })));
};
export var SearchResultView = function (p) {
    var name = p.name, description = p.description, url = p.url;
    return (React.createElement("div", { "data-cbtrack-searchresult": true, className: $(styles.result) },
        React.createElement("h3", null,
            React.createElement("a", { href: url }, name)),
        React.createElement("p", null, description),
        React.createElement("small", null, url)));
};
export var SearchResultsView = function (p) {
    var results = p.results;
    return (React.createElement("div", { className: $(styles.results) }, results.map(function (result, idx) { return React.createElement(SearchResultView, { key: idx, name: result.title, description: result.description, url: result.url }); })));
};
export var PaginationView = function (p) {
    var pagination = p.pagination, onClick = p.onClick;
    var last = pagination.last, previous = pagination.previous, next = pagination.next, pages = pagination.pages;
    return (React.createElement("div", { className: $(styles.pagination, "pagination") },
        React.createElement("nav", { "aria-label": "pagination" },
            React.createElement("ul", { className: "pagination" },
                previous !== '' && React.createElement("li", null,
                    React.createElement("a", { href: previous, onClick: function (e) { executeSearch(e, previous, onClick); } },
                        React.createElement("span", { className: "sr-only" }, "Go to page previous page"),
                        "Previous")),
                pages.map(function (page, idx) {
                    var _a;
                    return React.createElement("li", { key: idx, className: $((_a = {},
                            _a["active"] = page.selected === 'true' ? 'active' : false,
                            _a)) },
                        React.createElement("a", { href: page.link, onClick: function (e) { executeSearch(e, page.link, onClick); } },
                            React.createElement("span", { className: "sr-only" }, "Go to page "),
                            page.page));
                }),
                next !== '' && React.createElement("li", null,
                    React.createElement("a", { href: next, onClick: function (e) { executeSearch(e, next, onClick); } },
                        React.createElement("span", { className: "sr-only" }, "Go to page next page"),
                        "Next")),
                last !== '' && React.createElement("li", null,
                    React.createElement("a", { href: last, onClick: function (e) { executeSearch(e, last, onClick); } },
                        React.createElement("span", { className: "sr-only" }, "Go to page last page"),
                        "Last"))))));
};
export var FilterControlsView = function (p) {
    var activeFilters = p.activeFilters, resetURL = p.resetURL, onButtonClick = p.onButtonClick, onResetClick = p.onResetClick;
    return (React.createElement("div", { className: "visible-xs visible-sm hidden-md hidden-lg" },
        React.createElement("div", { className: $(styles.facet) },
            activeFilters.length > 0 &&
                React.createElement("ul", null, activeFilters.map(function (item, idx) {
                    return React.createElement(FacetView, { key: idx, isSelected: item.isSelected, query: item.query, title: item.title, total: item.total, undoquery: item.undoquery });
                })),
            React.createElement("div", { className: $(styles.controls) },
                React.createElement("button", { name: "add-filters", className: "btn btn-secondary", onClick: onButtonClick }, activeFilters.length ? 'Modify Filters' : 'Add Filters'),
                activeFilters.length > 0 && React.createElement("a", { href: resetURL, onClick: function (e) { executeSearch(e, resetURL, onResetClick, true); } }, "Clear All")))));
};

import { observable } from 'mobx';
import set from 'lodash/set';
var isSpanishLocaleString = function (s) { return /es/i.test(s); };
export var globalStore = observable({
    globalHeaderWrapper: null,
    locale: [document.documentElement.lang, navigator.language].some(isSpanishLocaleString) ? 'es' : 'en',
    get isEnglish() { return this.locale === 'en'; },
    get isSpanish() { return this.locale === 'es'; },
});
set(window, 'cb.atlas.globalStore', globalStore);

import React from "react";
import FooterStore from "./store";
import { Footer } from "./GlobalFooterWidget";
import { widget, globalStore } from "@widgets/core";
import ScrollToPlugin from "gsap/ScrollToPlugin";
var plugins = [ScrollToPlugin];
var pageScrollAnimate = function (destination, duration, easing, callback) {
    if (duration === void 0) { duration = 200; }
    if (easing === void 0) { easing = "linear"; }
    var easings = {
        linear: function (t) {
            return t;
        }
    };
    var start = window.pageYOffset;
    var startTime = "now" in window.performance ? performance.now() : new Date().getTime();
    var documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    var windowHeight = window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName("body")[0].clientHeight;
    var destinationOffset = typeof destination === "number" ? destination : destination.offsetTop;
    var destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight
        ? documentHeight - windowHeight
        : destinationOffset);
    if ("requestAnimationFrame" in window === false) {
        window.scroll(0, destinationOffsetToScroll);
        if (callback) {
            callback();
        }
        return;
    }
    var scroll = function () {
        var now = "now" in window.performance ? performance.now() : new Date().getTime();
        var time = Math.min(1, (now - startTime) / duration);
        var timeFunction = easings[easing](time);
        window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));
        if (window.pageYOffset === destinationOffsetToScroll) {
            if (callback) {
                callback();
            }
            return;
        }
        requestAnimationFrame(scroll);
    };
    scroll();
};
var setFocus = function () {
    //console.log("We are there")
    globalStore.globalHeaderWrapper && globalStore.globalHeaderWrapper.focus();
};
var scrollToGlobalHeader = function (e) {
    e.preventDefault();
    // TweenLite.to(window, 0.3, {
    //   scrollTo: { y: 0 },
    //   onComplete: () => globalStore.globalHeaderWrapper.focus(),
    // });
    var ms = 200;
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    ms = (scrollTop * ms) / 800;
    pageScrollAnimate(0, ms, "linear", setFocus);
};
export var FooterWidget = widget("footer")(function () { return new FooterStore(); }, function (store, tracker) {
    return (React.createElement(Footer, { showBackToTopLink: store.config.showBackToTopLink, showLinks: store.config.showLinks, programLinks: store.programLinks, organizationLinks: store.organizationLinks, extraLinks: store.extraLinks, globalHeaderId: globalStore.globalHeaderWrapper ? globalStore.globalHeaderWrapper.id : "", topClicked: scrollToGlobalHeader, tracker: tracker }));
});
export default FooterWidget;

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import levity from './index';
import { observable, computed } from 'mobx';
var getAPIKey = window.cb.core.utils.getAPIKey;
var LevityStore = /** @class */ (function () {
    function LevityStore() {
        var _this = this;
        this.authSession = null;
        this.update = function () {
            _this.authSession = levity.getAuthSession();
        };
        this.update();
        var eventBus = levity.getEventBus();
        eventBus.on(levity.events.Login, this.update);
        eventBus.on(levity.events.Logout, this.update);
    }
    Object.defineProperty(LevityStore.prototype, "isLoggedIn", {
        get: function () {
            return this.authSession.isLoggedIn;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LevityStore.prototype, "userType", {
        get: function () {
            return this.isLoggedIn ? (this.authSession.basicProfile.isStudent ? 'student' : 'professional') : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LevityStore.prototype, "sessionId", {
        get: function () {
            return this.authSession.sessionId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LevityStore.prototype, "firstName", {
        get: function () {
            var firstName;
            var token = (typeof levity.getJWTToken === 'function') ? levity.getJWTToken() : null;
            if (this.isLoggedIn) {
                //check Auth Session first fallback to JWTToken
                if (this.authSession.basicProfile && this.authSession.basicProfile.firstName) {
                    firstName = this.authSession.basicProfile.firstName;
                }
                else if (token) {
                    var parsedToken = JSON.parse(window.atob(token.split('.')[1]));
                    if (parsedToken.cb.dp && parsedToken.cb.dp.firstName) {
                        firstName = parsedToken.cb.dp.firstName;
                    }
                }
                /*
                // This code will need again when IAM team fix JWT Token
                //check JWTToken first then fallback to session
                if(token) {
                  let parsedToken = JSON.parse(window.atob(token.split('.')[1]));
                  
                  if(parsedToken.cb.dp && parsedToken.cb.dp.firstName) {
                    firstName = parsedToken.cb.dp.firstName;
                  }
                  // for Professional account
                  else if(this.authSession.basicProfile && this.authSession.basicProfile.firstName) {
                    firstName = this.authSession.basicProfile.firstName;
                  }
                }
            
                else if(this.authSession.basicProfile && this.authSession.basicProfile.firstName) {
                  firstName = this.authSession.basicProfile.firstName;
                  
                } */
            }
            return firstName || "";
        },
        enumerable: false,
        configurable: true
    });
    LevityStore.prototype.getAPIKey = function () {
        return getAPIKey();
    };
    __decorate([
        computed
    ], LevityStore.prototype, "isLoggedIn", null);
    __decorate([
        computed
    ], LevityStore.prototype, "userType", null);
    __decorate([
        computed
    ], LevityStore.prototype, "sessionId", null);
    __decorate([
        computed
    ], LevityStore.prototype, "firstName", null);
    __decorate([
        observable
    ], LevityStore.prototype, "authSession", void 0);
    return LevityStore;
}());
export default new LevityStore();

import React, { useEffect, useRef } from 'react';
import { capitalize } from 'lodash';
import { apricot4 } from '@widgets/core';
import $ from 'classnames';
/**
 * Apricot input element
 * @param props
 */
export var ApricotInput = function (_a) {
    var name = _a.name, label = _a.label, _b = _a.type, type = _b === void 0 ? 'text' : _b, _c = _a.defaultValue, defaultValue = _c === void 0 ? '' : _c, className = _a.className;
    var ref = useRef();
    var inputId = name; //`input-${name}-${uniqueId()}`;
    useEffect(function () {
        if (apricot4) {
            var CBForm = window.cb.apricot.CBForm;
            CBForm.floatingLabel(ref.current);
        }
    }, []);
    return apricot4 ? (React.createElement("div", { className: $("cb-input cb-floating-label", className) },
        React.createElement("label", { htmlFor: inputId }, label || capitalize(name)),
        React.createElement("input", { type: type, id: inputId, ref: ref, name: name }))) : (React.createElement("div", { className: "form-group" },
        React.createElement("label", { htmlFor: inputId }, label || capitalize(name)),
        React.createElement("input", { type: type, className: "form-control", id: inputId, name: name, defaultValue: defaultValue })));
};
/**
 * Apricot button element
 * @param props
 */
export var ApricotButton = function (_a) {
    var _b = _a.type, type = _b === void 0 ? "submit" : _b, _c = _a.cta, cta = _c === void 0 ? "primary" : _c, href = _a.href, children = _a.children, tracking = _a.tracking, className = _a.className, onClick = _a.onClick;
    var baseStyle = apricot4
        ? $('cb-btn', 'cb-btn-yellow', (cta !== 'primary') && 'cb-white-bg')
        : "btn btn-sm btn-" + cta;
    return (href || type === 'link') ? (React.createElement("a", { className: $(baseStyle, className), href: href, "data-cbtrack-linktype": tracking, onClick: onClick }, children)) : (React.createElement("button", { className: $(baseStyle, className), type: type, "data-cbtrack-linktype": tracking }, children));
};

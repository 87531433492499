var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Fragment } from 'react';
import LoginControl from '../LoginControl';
import SearchControl from '../SearchControl';
import includes from 'lodash/includes';
import $ from 'classnames';
import styles from '../styles.module.scss';
import localStyles from './styles.module.scss';
import strings from '../l10n';
import { levityStore as levity } from '@widgets/core';
var afterRender = function (fn) { setTimeout(fn, 0); };
var ControlsView = /** @class */ (function (_super) {
    __extends(ControlsView, _super);
    function ControlsView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loginControl = React.createRef();
        _this.searchControl = React.createRef();
        _this.closeLink = React.createRef();
        return _this;
    }
    ControlsView.prototype.focusOnCloseIcon = function () {
        this.closeLink.current.focus();
    };
    ControlsView.prototype.focusOnLoginControl = function () {
        var _this = this;
        afterRender(function () { _this.loginControl.current.focus(); });
    };
    ControlsView.prototype.focusOnSearchControl = function () {
        var _this = this;
        afterRender(function () { _this.searchControl.current.focus(); });
    };
    ControlsView.prototype.render = function () {
        var _this = this;
        var _a = this.props, activePanel = _a.activePanel, togglePanel = _a.togglePanel, useSearchWidget = _a.useSearchWidget, useLoginWidget = _a.useLoginWidget, searchConfig = _a.searchConfig, appId = _a.appId, destinationUrl = _a.destinationUrl, authEndpointParam = _a.authEndpointParam, idp = _a.idp, icons = _a.icons;
        var isOpen = includes(['login', 'search'], activePanel);
        var closePanelLabel = activePanel === 'login' ? strings.closeLoginPanel : strings.closeSearchPanel;
        var prepareLoginURL = function () {
            var baseLoginURL = 'https://account.collegeboard.org/login/login';
            var defaultUrl = /iframe/.test(window.location.href) ? window.parent.location.href : window.location.href;
            var queryParams = {
                appId: '' + appId,
                idp: idp || '',
                DURL: (destinationUrl === undefined) ? defaultUrl : destinationUrl
            };
            var loginURL = new URL(baseLoginURL);
            for (var key in queryParams) {
                if (queryParams.hasOwnProperty(key)) {
                    loginURL.searchParams.set(key, queryParams[key]);
                }
            }
            // add additional endpoint param for authentication URL
            if (authEndpointParam) {
                for (var key in authEndpointParam) {
                    if (authEndpointParam.hasOwnProperty(key)) {
                        loginURL.searchParams.set(key, authEndpointParam[key]);
                    }
                }
            }
            return loginURL.toString();
        };
        var clickedControl = function (panel) { return function (e) {
            e.preventDefault();
            if (panel == 'search' || levity.isLoggedIn) {
                togglePanel(panel);
            }
            else {
                window.top.location.href = prepareLoginURL();
            }
            afterRender(function () { return _this.closeLink.current.focus(); });
        }; };
        var closeLinkClicked = function (e) {
            e.preventDefault();
            togglePanel(null);
            afterRender(function () { return (activePanel === 'login' ? _this.loginControl : _this.searchControl).current.focus(); });
        };
        return (React.createElement("div", { className: $(styles.items, styles.controls, localStyles.hideoverflow) },
            React.createElement("div", { className: $(styles.items, localStyles.hideoverflow) }, !isOpen ?
                React.createElement(Fragment, null,
                    useLoginWidget && React.createElement(LoginControl, { onClick: clickedControl('login'), icons: icons, ref: this.loginControl }),
                    useSearchWidget && React.createElement(SearchControl, { searchConfig: searchConfig, icons: icons, ref: this.searchControl, onClick: clickedControl('search') })) :
                React.createElement("a", { ref: this.closeLink, className: $(styles.control, localStyles.root), "aria-label": closePanelLabel, href: "#close-panel", onClick: closeLinkClicked, "data-cbtrack-linktype": "toggle" }, icons ? React.createElement("span", { "aria-hidden": "true", className: 'cb-icon cb-x-mark' }) : React.createElement("span", { "aria-hidden": "true", className: 'cb-glyph cb-x-mark' })))));
    };
    return ControlsView;
}(React.Component));
export default ControlsView;
